<template>
  <div class="page">
    <lm-header :scrollTop="scrollTop" :bannerH="bannerH" :navH="navH" replaceHeader />
    <div ref="banner" class="banner">
      <div class="layout content">
        <div class="title">灵妙智造产品</div>
        <div class="info">系统集成，搭建企业数字化协作平台</div>
        <div class="button" @click="() => {$router.push('/relation')}">立即体验</div>
      </div>
    </div>
    <div class="navBox" ref="nav" :style="navStyle">
      <div class="nav">
        <div ref="nav0" class="title" :class="{ active: navActive == 'MES' }" @click="navActiveChang('MES')">制造执行系统MES</div>
        <div ref="nav1" class="title" :class="{ active: navActive == 'SRM' }" @click="navActiveChang('SRM')">供应链关系管理系统SRM</div>
        <div ref="nav3" class="title" :class="{ active: navActive == 'LES' }" @click="navActiveChang('LES')">物流执行系统LES</div>
        <div ref="nav2" class="title" :class="{ active: navActive == 'WMS' }" @click="navActiveChang('WMS')">仓库管理系统WMS</div>
        <div ref="nav4" class="title" :class="{ active: navActive == 'QMS' }" @click="navActiveChang('QMS')">质量管理系统QMS</div>
        <div class="activebox" :style="activeStyle">
          <div></div>
        </div>
      </div>
    </div>
		<div v-show="this.scrollTop >= this.bannerH" style="width:100px;height:80px;"></div>
    <template v-if="navActive == 'MES'">
      <mes />
    </template>
    <template v-else-if="navActive == 'SRM'">
      <srm />
    </template>
    <template v-else-if="navActive == 'LES'">
      <les />
    </template>
    <template v-else-if="navActive == 'WMS'">
      <wms />
    </template>
    <template v-else-if="navActive == 'QMS'">
      <qms />
    </template>
    <lm-footer />
  </div>
</template>

<script>
  import LmHeader from '@/components/header';
  import LmFooter from '@/components/footer';
  import Mes from './mes';
  import Srm from './srm';
  import Les from './les';
  import Wms from './wms';
  import Qms from './qms';
  const navList = [
    'MES',
    'SRM',
    'WMS',
    'LES',
    'QMS'
  ]
  export default {
    components: {
      LmHeader,
      LmFooter,
      Mes,
      Srm,
      Les,
      Wms,
      Qms
    },
    computed: {
      activeStyle() {
        const style = {};
        style.width = this.navWidth[navList.indexOf(this.navActive)] + 'px';
        style.left = this.navLeft[navList.indexOf(this.navActive)] + 'px';
        return style
      },
      navStyle() {
        let data = {}
        if (this.scrollTop >= this.bannerH) data = {
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 100
        }
        else data = {}
        return data
      }
    },
    data() {
      return {
        scrollTop: 0,
        navActive: null,
        navWidth: [],
        navLeft: [],
        bannerH: 450,
        navH: 40
      }
    },
    mounted() {
      this.bannerH = this.$refs.banner.offsetHeight;
      this.navH = this.$refs.nav.offsetHeight;
      window.addEventListener('scroll', this.getScroll);
      this.navWidth = [this.$refs.nav0.clientWidth, this.$refs.nav1.clientWidth, this.$refs.nav2.clientWidth, this.$refs.nav3.clientWidth, this.$refs.nav4.clientWidth];
      this.navLeft = [this.$refs.nav0.offsetLeft, this.$refs.nav1.offsetLeft, this.$refs.nav2.offsetLeft, this.$refs.nav3.offsetLeft, this.$refs.nav4.offsetLeft];
			this.getScroll();
      if(this.$route.params.type) this.navActive = this.$route.params.type
      else this.navActive = 'MES';
    },
    methods: {
      getScroll() {
        this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      },
      navActiveChang(idx) {
        if (this.scrollTop > this.bannerH) document.body.scrollTop = document.documentElement.scrollTop = this.bannerH
        this.navActive = idx
      }
    }
  }
</script>

<style lang="less" scoped>
  .banner {
    width: 1920px;
    height: 450px;
    background: url('./assets/banner.png') no-repeat;
    background-size: 1920px;
    position: relative;
    .content {
      padding-top: 155px;
      line-height: 1;
      text-align: left;
      color: #ffffff;
      .title {
        font-size: 50px;
        padding-bottom: 30px;
      }
      .info {
        font-size: 22px;
        padding-bottom: 53px;
      }
      .button {
        display: inline-block;
        font-size: 18px;
        padding: 15px 34px;
        background: #FF9000;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
  .navBox {
    width: 100vw;
    background-color: white;
    box-shadow: 0px 6px 20px 0px rgba(142, 171, 201, 0.12);
    .nav {
      width: 1240px;
      height: 80px;
      margin: 0 auto;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      position: relative;
      .title {
        padding-top: 30px;
        cursor: pointer;
        transition: all .5s;
        &.active {
          color: #218AEF;
        }
        &:hover {
          color: #218AEF;
        }
      }
      .activebox {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 3px;
        background: #218AEF;
        min-width: 100px;
        transition: all 0.5s;
        text-align: center;
        div {
          display: inline-block;
          width: 55%;
          height: 20px;
          background: linear-gradient(to bottom, #FFFFFF, #D3EBFF);
          transform: translateY(-20px);
        }
      }
    }
  }
</style>
